import * as ReactGA from 'react-ga'
const analyticsKey = 'G-SWB5XMMFB8'
ReactGA.initialize(analyticsKey)

export function sendGoogleAnalytics(action: string, value: string) {
  const ga = ReactGA.ga()
  ga('create', analyticsKey, 'auto', 'themeBuilderTracker')
  ga('themeBuilderTracker.send', 'event', 'ColorTheme', action, value)
}

export function sendGAPageView(path: string) {
  ReactGA.pageview(path)
}
