import {Typography} from '@mui/material'
import {GoogleAd} from './google-ad'

// This component is a container of Ads that can be used in the application.
export function StackedAdContainer() {
  return (
    <div>
      <div style={{padding: '8px'}}>
        <Typography variant='body1' align='center'>
          Ads
        </Typography>
      </div>
      <GoogleAd client='ca-pub-1992076672624577' slot='4356116868' />
    </div>
  )
}
