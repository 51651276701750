import {Tab, Tabs} from '@mui/material'
import {Location} from 'history'
import { useEffect } from 'react';

import {useHistory} from 'react-router-dom'
import {sendGAPageView} from '../../utils/analyticsHelper'

export const paths = ['/', '/page-two']
export const pages = [
  {path: '/', title: 'PageOne'},
  {path: '/page-two', title: 'PageTwo'},
]

export function TabNavigation(props: TabNavigationProps) {
  const {location} = props
  useEffect(() => {
    sendGAPageView(location.pathname)
  }, [location.pathname])
  let history = useHistory()

  return (
    <>
      <Tabs
        value={location.pathname}
        indicatorColor='primary'
        textColor='primary'
        centered
      >
        {pages.map((page, index) => (
          <Tab
            key={page.path}
            label={page.title}
            value={page.path}
            onClick={() => {
              history.push(page.path)
              sendGAPageView(page.title)
            }}
          />
        ))}
      </Tabs>
    </>
  )
}

export type TabNavigationProps = {
  location: Location
}
