import {useEffect} from 'react'
import {makeStyles} from '@mui/styles'
import {Paper, Theme} from '@mui/material'

import {PageContainer, StackedAdContainer} from '../components'

const useStyles = makeStyles((theme: Theme) => ({
  adsContainer: {
    backgroundColor: '#fcfcfc',
    width: 'auto',
    height: 'auto',
    margin: theme.spacing(1),
    marginBottom: theme.spacing(6),
  },
  root: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
}))

export function PageTwo() {
  const classes = useStyles()

  // REFRESH ADS WHEN THE PAGE LOADS
  useEffect(() => {}, [])

  return (
    <PageContainer>
      <div className={classes.root}>
        <Paper className={classes.adsContainer}>
          <StackedAdContainer />
        </Paper>
      </div>
    </PageContainer>
  )
}
